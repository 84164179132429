<template>
    <b-card title="Edit MenuData ">
        <validation-observer ref="simpleRules">
            <b-form @submit.prevent>
                <b-row>
                    <!-- Name -->
                    <b-col cols="12">
                        <b-form-group label="Name" label-for="name">
                            <validation-provider name="Name" rules="required|max:20" #default="{ errors }">
                                <b-input-group class="input-group-merge"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="UserIcon" />
                                    </b-input-group-prepend>

                                    <b-form-input id="name" v-model="item.name"
                                        :state="errors.length > 0 ? false : null" placeholder="Name" />
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

               
           


                    <!-- submit and reset -->
                    <b-col>
                        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit"
                            @click.prevent="validationForm" variant="primary" class="mr-1">
                            Submit
                        </b-button>
                        <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="reset" variant="outline-secondary"
                            @click="reset">
                            Reset
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
    </b-card>
</template>

<script>
import {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BFormText,
    BFormDatalist,
    BFormInvalidFeedback,
    BInputGroup,
    BInputGroupPrepend,
    BFormValidFeedback,
    BFormFile,
    BFormSelect,
    BFormTextarea,
    BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
//Database
import { db, storage } from "@/main";
import firebase from "firebase";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

//Vee-Validate 
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import {
    required,
} from '@validations'

export default {
    components: {
        BCard,
        BRow,
        BCol,
        BFormGroup,
        BInputGroup,
        BInputGroupPrepend,
        BFormInput,
        BFormCheckbox,
        BForm,
        BFormText,
        BButton,
        BFormDatalist,
        BFormInvalidFeedback,
        BFormValidFeedback,
        BFormFile,
        BFormSelect,
        BFormTextarea,
        BImg,
        ValidationProvider,
        ValidationObserver,
        required,
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            item:{},
            selectedFlavor: '',
            selc:'',
            // description: '',
            previewImg: '',
            meta_title: '',
            meta_description: '',
            meta_keywords: '',
            profileImg: '',
            // selectedoptions: null,
            // selectedCountry: null,
            name: '',
            image: '',
            checkedCategories:'', 
            // checkedCategoriesc:'',

            options: [
                // {'value': null, text: 'Please select Publish / Unpblish'},
                {value: 1, 'text': 'published'},
                {value: 0, 'text': 'Unpublished'},

            ],

            countryOptions: [
                { value: null, text: 'Please select a country' },
            ],
            editor: ClassicEditor,
                editorData: '<p>Content of the editor.</p>',
                editorConfig: {
                    // The configuration of the editor.
                    
                }
        }
    },
    created() {
        this.getItem();
        this.getCountries();
    },
    computed: {
        validation() {
            return this.name.length > 4 && this.name.length < 13
        },
    },
    methods: {
        change(e) {
              this.checkedCategories = e.target.value;
            //   alert(checkedCategories);
          },

        getItem()
            {
                var id = this.$route.params.id;

                db.collection('subcategory').doc(id).get().then((doc) => {
                    console.log(doc.data());
                    this.item = doc.data();
                    this.selc = doc.data().checkedCategories;
                    this.selectedFlavor =  parseInt(doc.data().checkedCategories);
                    this.previewImg = doc.data().image;
                })
                console.log(doc.id, " => ", doc.data())
            },

      

        reset() {
            this.name = '',
            this.selectedFlavor = '',
            this.description = '',
            this.previewImg = '',
            this.meta_title = '',
            this.meta_description = '',
            this.meta_keywords = '',
            this.profileImg = '',
            this.selectedoptions= null,
            this.selectedCountry = null;
        },

        validationForm() {
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    // eslint-disable-next-line
                    // alert('form submitted!')
                    this.submit();
                }
            })
        },

        submit() {
            store.dispatch('app/commitActivateLoader');
             var date = new Date().toLocaleString();//.toISOString().slice(0, 10);
             var id = this.$route.params.id;
                 
                if(this.checkedCategories == undefined){
                  this.checkedCategories = this.selc;
                    alert(this.checkedCategories);
                }

                db.collection("subcategory")
                .doc(id)
                .update({
                  name: this.item.name,
                  modified: date,
                })
                                .then((doc) => {
                                    console.log("Added Successfully!");
                                 
                                   
                                
                                            store.dispatch('app/commitDeactivateLoader');
                                            this.$router.push({ name: 'subcategory-list' });
                                            this.showNotification('Data Updated Successfully!', 'AlignJustifyIcon', 'success');
                                        
                                
                               
                         
                                })
                      
        },

        showNotification(title, icon, variant) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title,
                    icon,
                    variant,
                },
            })
        },
    },
}
</script>